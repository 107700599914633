import { ethers } from "ethers";
import { string_to_uint_array, convert_DER_to_ECDSA, to_buffer, decode_user_handle, hex_string_to_byte_array, byte_array_to_hex_string, generate_random_string, to_uint8array } from "./encodings";

// -- TEMP: MIGRATE TO CONFIG
let app_name = "JetBolt";
// let app_domain = "localhost";
// let app_domain = "sleek-f98ef.web.app";
let app_domain = "jetbolt.io";

export async function create_credential(user_name, encryption_key) {

    let challenge = generate_random_string(10);

    // Ensure WebAuthN is available
    if (!window.PublicKeyCredential) {
        alert("Web Authentication API not supported in this browser.");
        // return;
        throw "Error";
    }

    if (!user_name) {
        user_name = "JetBolt Wallet";
    }

    // Create the credention with desired options
    let creation_options = {
        challenge: string_to_uint_array(challenge),
        rp: {
            name: app_name,
            id: app_domain
        },
        user: {
            id: to_uint8array(encryption_key),
            name: `${user_name}`,
            displayName: `${user_name}`
        },
        pubKeyCredParams: [
            { type: "public-key", alg: -7 },
            { type: "public-key", alg: -257 }
        ],
        authenticatorSelection: {
            authenticatorAttachment: "platform",
            userVerification: "preferred",
            requireResidentKey: true
        },
        timeout: 1 * 60 * 1000,
        attestation: "none",
    };

    // Make credential
    let credential = await navigator.credentials.create({ publicKey: creation_options });
    return credential.id;

}

export async function assert_credential(credential_id) {

    let challenge = generate_random_string(10);

    let assertion_options = {
        challenge: string_to_uint_array(challenge),
        allowCredentials: [{
            type: "public-key",
            id: to_buffer(credential_id),
            transports: ["internal"]
        }],
        timeout: 1 * 60 * 1000,
        rpId: app_domain,
        userVerification: "preferred"
    };

    // Assert credential
    let credential = await navigator.credentials.get({ publicKey: assertion_options });

    return to_uint8array(credential.response.userHandle);

}