import { sign_and_call, view_call, make_random_address } from "./core";

let contract_name = "Friends";

// ---- Public Functions ----

export async function set_username(username, callback) {
    let response = await sign_and_call(contract_name, "set_username", [username], 0, callback);
    return response;
}

export async function add_friend_by_username(username, callback) {
    let response = await sign_and_call(contract_name, "add_friend_by_username", [username], 0, callback);
    return response;
}

export async function add_friend(recipient_address, callback) {
    let response = await sign_and_call(contract_name, "add_friend", [recipient_address], 0, callback);
    return response;
}

export async function accept_friend(requester_address, callback) {
    let response = await sign_and_call(contract_name, "accept_friend", [requester_address], 0, callback);
    return response;
}

export async function decline_friend(requester_address, callback) {
    let response = await sign_and_call(contract_name, "decline_friend", [requester_address], 0, callback);
    return response;
}

export async function remove_friend(friend_address, callback) {
    let response = await sign_and_call(contract_name, "remove_friend", [friend_address], 0, callback);
    return response;
}

export async function favorite(user_address, callback) {
    let response = await sign_and_call(contract_name, "favorite", [user_address], 0, callback);
    return response;
}

export async function unfavorite(user_address, callback) {
    let response = await sign_and_call(contract_name, "unfavorite", [user_address], 0, callback);
    return response;
}

// ---- Getter Functions ----

export async function check_username_availability(username) {
    let response = await view_call(contract_name, "check_username_availability", [username]);
    return response;
}

export async function get_username(user_address) {
    let response = await view_call(contract_name, "get_username", [user_address]);
    return response;
}

export async function get_profile(user_address) {
    let response = await view_call(contract_name, "get_profile", [user_address]);
    return response;
}

export async function get_profile_from(user_address, requester_address) {
    let response = await view_call(contract_name, "get_profile_from", [user_address, requester_address]);
    return response;
}

export async function get_friends(user_address) {
    let response = await view_call(contract_name, "get_friends", [user_address]);
    return response;
}

export async function get_favorites_addresses(user_address) {
    let response = await view_call(contract_name, "get_favorites_addresses", [user_address]);
    return response;
}

export async function get_favorites(user_address) {
    let response = await view_call(contract_name, "get_favorites", [user_address]);
    return response;
}

export async function get_friend_requests(user_address) {
    let response = await view_call(contract_name, "get_friend_requests", [user_address]);
    return response;
}

export async function get_outgoing_friend_requests(user_address) {
    let response = await view_call(contract_name, "get_outgoing_friend_requests", [user_address]);
    return response;
}


// (async function () {

//     setTimeout(async function () {
//         let address = await view_call(contract_name, "get_address", ["cfloyd92"]);
//         console.log({address});
//     }, 5000)
// })();