import { update_exchange_rate } from "./api"
import { currencies } from "../assets/data/currencies";

let ga_tags = ["G-J8FCVNEKRG"];
let fb_event_map = {
    "open_purchase_modal": "AddToCart",
    "begin_checkout": "InitiateCheckout",
    "connect_wallet": "AddPaymentInfo",
    "purchase": "Purchase",
}
let add_roll_event_map = {
    "open_purchase_modal": "9dd5de7e",
    "begin_checkout": "af038f58",
    "connect_wallet": "6201a18b",
    "purchase": "683e49ff",
}
let blockchain_ads_script_map = {
    "open_purchase_modal": "https://bca-protocol.firebaseapp.com/conversion-track.js?tagID=0475e35f-4a8a-492e-a5ac-c0d33e3d347b&apiKey=9676ceae-8d9d-4e24-bb67-c6a3cad14539",
    "begin_checkout": "https://bca-protocol.firebaseapp.com/conversion-track.js?tagID=d531ffc4-1c59-4184-a566-9fcb0acca49f&apiKey=302bb1f9-cde4-4b03-9e3e-6de475e4ad6d",
    "connect_wallet": "https://bca-protocol.firebaseapp.com/conversion-track.js?tagID=4b257246-30fd-4917-92fc-0fdf2330d41a&apiKey=993a1c83-bc43-42b4-81e8-76cfe758d7a6",
    "purchase": "https://bca-protocol.firebaseapp.com/conversion-track.js?tagID=c36da353-2592-42af-80b9-af2d5c493612&apiKey=0bed94d7-0913-45d7-871d-0f4ff0d8f811",
}
let twitter_event_map = {
    "open_purchase_modal": "tw-on5ad-ong61",
    "begin_checkout": "tw-on5ad-ong62",
    "connect_wallet": "tw-on5ad-ong63",
    "purchase": "tw-on5ad-ong64",
}
let tnn_map = {
    "open_purchase_modal": "AW-16700876044/fMKaCM3VkdMZEIzKzJs-",
    "begin_checkout": "AW-16700876044/kn7mCNDVkdMZEIzKzJs-",
    "connect_wallet": "AW-16700876044/t8OjCNLakdMZEIzKzJs-",
    "purchase": "AW-16700876044/IyBICIqUpNIZEIzKzJs-"
}

function trigger_addressable(name, value) {
    try {
        window.__adrsbl.run(name, name=="purchase", [{"name":"amount","value":value},{"name":"price","value":value}]);
    } catch (error) {}
}

function g_ads(name, value) {
    try {

        window.gtag('event', 'conversion', {
            'send_to': tnn_map[name],
            'value': value,
            'currency': 'USD',
            'transaction_id': ''
        });

        // if (name == "purchase") {
        //     window.gtag('event', 'conversion', {
        //         'send_to': 'AW-16678458049/RzGBCM3EmMwZEMGl9JA-',
        //         'value': value,
        //         'currency': 'USD',
        //         'transaction_id': ''
        //     });
        // }

    } catch (error) {}
}

function trigger_ga_event(name, value) {
    for (let i = 0; i < ga_tags.length; i++) {
        try {

            window.gtag(
                "event",
                name,
                value > 0 ?
                {
                    value,
                    currency: 'USD',
                    'send_to': ga_tags[i]
                } :
                {
                    'send_to': ga_tags[i]
                }
            );

        } catch (error) {}
    }
}

function track_fb(name, value) {
    try {
        window.fbq(
            "track",
            fb_event_map[name],
            value > 0 ?
            {
                value: value,
                currency: 'USD'
            } : {}
        )
    } catch (error) {}
}

function track_twitter(name, value) {
    try {
        window.twq('event', twitter_event_map[name],
            value > 0 ?
            {
                value: value,
                currency: 'USD'
            } : {}
        );
    } catch (error) {}
}

function add_roll_track(name, value) {
    try {
        window.__adroll.record_user({"adroll_segments": add_roll_event_map[name]});

        if (name == "purchase" && value > 0) {
            window.adroll.track("purchase", {
                "conversion_value": value,
                "currency": "USD",
                "products": []
            }); 
        }

    } catch (error) {}
}

function blockchain_ads_track(name, value) {
    try {
        const script = document.createElement('script');
        script.src = blockchain_ads_script_map[name];
        script.async = true;
        document.body.appendChild(script);         
    } catch (error) {
        console.log(error)
    }
}

function iframe_track(name, value) {
    try {

        let iframe = document.createElement("iframe");
        iframe.style = "position: absolute; width: 50px; height: 50px; top: -100px; left: -100px;";
        iframe.src = `https://technow.news/purchase?name=${name}&value=${value}`;
        document.body.appendChild(iframe);

    } catch (error) {

    }
}

export function trigger_event(name, value) {
    // console.log("trigger_event",{name, value});
    trigger_ga_event(name, value);
    track_fb(name, value);
    add_roll_track(name, value);
    blockchain_ads_track(name, value);
    g_ads(name, value);
    trigger_addressable(name, value);
    track_twitter(name, value);
    // iframe_track(name, value);
}

export async function trigger_event_crypto(name, value, currency_index) {
    try {
        let exchange_rates = await update_exchange_rate();
        let rate = exchange_rates.exchange_rates[currencies[currency_index].symbol];
        let real_value = parseFloat((rate * value).toFixed(2));
        trigger_event(name, real_value);
    } catch (error) {}
}