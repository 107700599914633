import React, { useEffect, useState, useRef, useCallback } from "react";

// ---- Configs ----
import config from "../configs/config.json";
import external_links from "../configs/external_links.json";

// ---- Data ----
import { currencies } from "../assets/data/currencies";
import { lootboxes } from "../assets/data/lootboxes";

// ---- Icons ----
import logo_dark_icon from "../assets/icons/logo_dark.png";
import right_icon from "../assets/icons/right.png";
import down_icon from "../assets/icons/unfold.png";
import equal_icon from "../assets/icons/equal.png";
import edit_icon from "../assets/icons/edit.png";
import tick_icon from "../assets/icons/tick.png";

// ---- Modals ----
import { useErrorPopUp } from "../modals/error_pop_up";
import { useCurrencySelector } from "../modals/currency_selector";
import { usePurchaseFunnel } from "../modals/purchase_funnel";
import { useDiscountCharts } from "../modals/discount_charts";
import { useLoadingOverlay } from "../modals/loading";

// ---- Services ----
import { get_next_price_increase, get_tokens_for_usd } from "../services/contract_functions/token";
import { format_time_mm_ss, increment_ref, decrement_ref, is_number, on_focus_input_ref, on_blur_input_ref, select_input_ref, add_thousand_separator } from "../services/helper_functions";
import { update_exchange_rate, create_customer, create_payment, add_destination_address, check_spend, get_total_sales } from "../services/api"
import useCustomer from "../services/wallet/useCustomer";
import customer_instance from "../services/wallet/customer";
import useToken from "../services/wallet/useToken";
import useAttribution from "../services/useAttribution";
import { check_if_location_blocked } from "../services/location"
import { get_country } from "../services/location";

// ---- Ethers ----
import { ethers, parseEther } from "ethers";

// ---- Unlimit ----
import { GateFiSDK, GateFiEventTypes } from "@gatefi/js-sdk";

function Sales_Widget_Inner({ hard_stop=false, geo_error=function(){} }) {

    const { sources, add_source } = useAttribution();

    // ---- Token ----
    let token = useToken();

    // ---- Error Modal ----
    let { throw_error } = useErrorPopUp();

    function sample_error() {
        let params = {
            title: "Oh No!",
            content: "Some explanation text in large font and really freaking simple. Should be only this long.",
            buttons: [
                {
                    title: "Confirm",
                    callback: function() {
                        console.log("HELLO WORLD")
                    }
                },
                {
                    title: "Dismiss"
                }
            ]
        }
        throw_error(params);
    }

    // ---- Pre Calc Limits ----
    let [current_spend, set_current_spend] = useState(0);
    useEffect(()=>{
        (async function() {
            if (customer && customer.customer_id) {
                let { current_spend } = await check_spend(customer.customer_id, "ETH");
                set_current_spend(current_spend);
            }
        })();
    },[]);

    let { open_loading_overlay, close_loading_overlay } = useLoadingOverlay();
 
    // ---- Run Purchase ----
    let customer = useCustomer();

    async function purchase(event) {

        if (hard_stop) {
            geo_error();
            return;
        }

        event.target.style = "opacity: 0.25; pointer-events: none;";

        try {

            if (!agreed_tos) {
                let params = {
                    title: "Oh No!",
                    content: "You must first agree to our terms and conditions before continuing.",
                    buttons: [
                        {
                            title: "Okay"
                        }
                    ]
                }
                throw_error(params);
                event.target.style = "opacity: 1; pointer-events: auto;";
                return;
            }

            if (input_amount_ref.current && currency_index > 0) {

                let hint_amount = input_amount_ref.current.value;

                let currency = currencies[currency_index];

                if (hint_amount < currency.min || hint_amount > currency.max) {
                    let params = {
                        title: "Oh No!",
                        content: hint_amount < currency.min ?
                            `The minimum purchase value is US$ 10 (${currency.min} ${currency.symbol})` :
                            `The maximum purchase value is US$ 10,000 (${currency.max} ${currency.symbol})`,
                        buttons: [
                            {
                                title: hint_amount < currency.min ?
                                    "Set To Min" :
                                    "Set To Max",
                                callback: function() {
                                    if (hint_amount < currency.min) {
                                        input_amount_ref.current.value = currency.min;
                                    }
                                    else {
                                        input_amount_ref.current.value = currency.max;
                                    }
                                    input_value_changed();
                                }
                            },
                            {
                                title: "Dismiss"
                            }
                        ]
                    }
                    throw_error(params);
                    event.target.style = "opacity: 1; pointer-events: auto;";
                    return;
                }

                open_loading_overlay();

                if (customer && customer.customer_id) {

                    // let { current_spend } = await check_spend(customer.customer_id, currency.symbol);

                    let usd_purchase_value = parseFloat(hint_amount) * exchange_rates[currency.symbol];
                    if (current_spend + usd_purchase_value > 25000) {
                        let max_allowance = 25000 - current_spend;
                        let max_allowance_currency = max_allowance / exchange_rates[currency.symbol];
                        let params = {
                            title: "Oh No!",
                            content: `It looks like you are trying to purchase more than our monthly limit of US$25,000 or equivalent cryptocurrency per customer. You have already spent ${add_thousand_separator(current_spend.toFixed(0))} this month. You can now only purchase for a maximum of US$${add_thousand_separator(max_allowance)} (${max_allowance_currency.toFixed(currency.sig_figs)} ${currency.symbol})`,
                            buttons: [
                                {
                                    title: "Set Max",
                                    callback: function() {
                                        input_amount_ref.current.value = max_allowance_currency;
                                    }
                                },
                                {
                                    title: "Dismiss"
                                }
                            ]
                        }
                        throw_error(params);
                        event.target.style = "opacity: 1; pointer-events: auto;";
                        return;
                    }
                    else {

                        let { payin_address, payment_id, deadline_at } = await create_payment(customer.customer_id, hint_amount, currency_index);
                        if (token && token.wallet_address && !customer.destination_address) {

                            await add_destination_address(customer.customer_id, customer.secret_code, token.wallet_address);
                            customer_instance.add_destination_address(token.wallet_address);
                        }
                        close_loading_overlay();
                        open_purchase_funnel({type: "pay_evm", hint_amount, payin_address, payment_id, currency_index, deadline_at});
                    }
                }
                else {
                    customer_instance.init();
                    let { secret_code } = customer_instance.current
                    let { customer_id, payin_address, payment_id, deadline_at } = await create_customer(secret_code, hint_amount, currency_index, sources);
                    customer_instance.add_id(customer_id);
                    if (token && token.wallet_address) {
                        await add_destination_address(customer_id, secret_code, token.wallet_address);
                        customer_instance.add_destination_address(token.wallet_address);
                    }
                    close_loading_overlay();
                    open_purchase_funnel({type: "pay_evm", hint_amount, payin_address, payment_id, currency_index, deadline_at});
                }
            }
            else if (input_amount_ref.current) {
                let hint_amount = input_amount_ref.current.value;
                let min_amount = 10;
                if (hint_amount < min_amount || hint_amount > 10000) {
                    let params = {
                        title: "Oh No!",
                        content: hint_amount < min_amount ?
                            `The minimum purchase value is US$ ${min_amount}.` :
                            `The maximum purchase value is US$ 10,000.`,
                        buttons: [
                            {
                                title: hint_amount < min_amount ?
                                    "Set To Min" :
                                    "Set To Max",
                                callback: function() {
                                    if (hint_amount < min_amount) {
                                        input_amount_ref.current.value = min_amount;
                                    }
                                    else {
                                        input_amount_ref.current.value = 10000;
                                    }
                                    input_value_changed();
                                }
                            },
                            {
                                title: "Dismiss"
                            }
                        ]
                    }
                    throw_error(params);
                    event.target.style = "opacity: 1; pointer-events: auto;";
                    return;
                }

                if (!customer || !customer.customer_id) {
                    customer_instance.init();
                    let { secret_code } = customer_instance.current
                    let { customer_id } = await create_customer(secret_code, hint_amount, 0, sources);
                    customer_instance.add_id(customer_id);
                    if (token && token.wallet_address) {
                        await add_destination_address(customer_id, secret_code, token.wallet_address);
                        customer_instance.add_destination_address(token.wallet_address);
                    }
                }
                else {
                    // let { current_spend } = await check_spend(customer.customer_id, "USD");
                    if (current_spend + parseFloat(hint_amount) > 25000) {
                        let max_allowance = 25000 - current_spend;
                        let params = {
                            title: "Oh No!",
                            content: `It looks like you are trying to purchase more than our monthly credit card limit of US$25,000. You have already spent ${add_thousand_separator(current_spend.toFixed(0))} this month. You can now only purchase for a maximum of US$${add_thousand_separator(max_allowance)}`,
                            buttons: [
                                {
                                    title: "Set Max",
                                    callback: function() {
                                        input_amount_ref.current.value = max_allowance;
                                    }
                                },
                                {
                                    title: "Dismiss"
                                }
                            ]
                        }
                        throw_error(params);
                        event.target.style = "opacity: 1; pointer-events: auto;";
                        return;
                    }
                }

                // open_purchase_funnel({type: "pay_card", hint_amount});

                // let country = await get_country();
                // if (country == "US") {
                //     open_purchase_funnel({type: "pay_wert", hint_amount});
                // }
                // else {
                //     open_purchase_funnel({type: "pay_card", hint_amount});
                // }
                // open_purchase_funnel({type: "pay_wert", hint_amount});
                open_purchase_funnel({type: "pay_tweed", hint_amount});
                
            }
        } catch (error) {
            console.log(error);
            close_loading_overlay();
            // document.body.appendChild()
        }

        event.target.style = "opacity: 1; pointer-events: auto;";
    }

    // ---- Currency Modal ----
    let { open_currency_selector } = useCurrencySelector();

    // ---- Purchase Funnel ----
    let { open_purchase_funnel } = usePurchaseFunnel();

    function open_evm_payment() {
        open_purchase_funnel({type: "evm"});
    }

    // ---- Refs ----
    let input_amount_ref = useRef(null);
    let input_amount_outter_ref = useRef(null);
    let output_amount_ref = useRef(null);

    // ---- Exchange Rates ----
    let [currency_index, set_currency_index] = useState(0);
    let [exchange_rates, set_exchange_rates] = useState({});
    let [next_discount, set_next_discount] = useState(1);

    // ---- Onload ----
    useEffect(() => {
        (async function() {
            let exchange_rates = await update_exchange_rate();
            set_exchange_rates(exchange_rates.exchange_rates);

            currencies.map(currency => {
                const rate = exchange_rates.exchange_rates[currency.symbol];
                if (rate) {
                    const minVal = 10 / rate;
                    const maxVal = 10000 / rate;

                    const power = Math.pow(10, currency.sig_figs);

                    currency.min = Math.ceil(minVal * power) / power;
                    currency.max = Math.floor(maxVal * power) / power;
                    currency.discounts = [];

                    for (let i = 0; i < lootboxes.length; i++) {
                        let lootbox = lootboxes[i];
                        currency.discounts.push({
                            title: lootbox.title,
                            discount: lootbox.discount,
                            usd_threshold: lootbox.usd_threshold,
                            value: (lootbox.usd_threshold / rate).toFixed(currency.sig_figs)
                        });
                    }
                }
                return currency;
            });

        })();
    }, []);

    let debounce_timer = useRef(null);

    const debounce_input_value_changed = (value, index) => {
        
        if (debounce_timer.current) {
            clearTimeout(debounce_timer.current);
        }

        debounce_timer.current = setTimeout(async ()=>{
            await input_value_changed(value, index);
        },300);
    }

    // const debounce_input_value_changed = useCallback(debounce((value, index) => {
    //     input_value_changed(value, index).catch(error => {
    //         console.error("Error in async operation:", error);
    //     });
    // }, 300), []);

    async function input_value_changed(value, index) {
        if (input_amount_ref.current && output_amount_ref.current) {
            try {
                if (!value || !is_number(value)) {
                    value = input_amount_ref.current.value;
                }
                let currency = currencies[currency_index];
                if (index || index == 0) {
                    currency = currencies[index];
                }
                
                let discounts = currency.discounts;

                let _next_discount;
                for (let i = 0; i < discounts.length; i++) {
                    let discount = discounts[i];
                    if (parseFloat(value) < parseFloat(discount.value)) {
                        if (i > 0) {
                            discount.current = discounts[i-1];
                        }
                        discount.distance = value / discount.value * 100;
                        _next_discount = discount;
                        break;
                    }
                }

                set_next_discount(_next_discount);

                let currency_symbol = currency.symbol;
                let exchange_rate = exchange_rates[currency_symbol];
                let usd_amount = parseFloat(value) * exchange_rate;
                let usd_amount_18 = ethers.parseEther(usd_amount.toString());
                let jetpaw_amount = await get_tokens_for_usd(usd_amount_18);
                let jet_paw_decimal = ethers.formatUnits(jetpaw_amount);
                output_amount_ref.current.value = parseFloat(jet_paw_decimal).toFixed(2);
            } catch (error) {

            }
        }
    }

    useEffect(()=> {
        if (input_amount_ref.current && output_amount_ref.current) {
            input_amount_ref.current.value = currencies[currency_index].default_value;
            input_value_changed();
        }
    },[exchange_rates])

    let payment_list_scrollspace = useRef(null);

    useEffect(()=> {

        let temp_currency_index = currency_index; // - 1;
        temp_currency_index = temp_currency_index>=5?currency_index-1:temp_currency_index;

        input_amount_ref.current.value = currencies[currency_index].default_value;
        input_value_changed();

        let payment_method_items = payment_list_scrollspace.current.querySelectorAll(".payment_method_item")
        let payment_method_item = payment_method_items[temp_currency_index];
        let item_position = payment_method_item.offsetLeft - 22;
        payment_list_scrollspace.current.scrollTo({
            left: item_position,
            behavior: 'smooth'
        });

    },[currency_index]);

    // ---- Dragging ----
    const [is_dragging, set_is_dragging] = useState(false);
    const [start_pos, set_start_pos] = useState(0);
    const [scroll_pos, set_scroll_pos] = useState(0);
    const [is_dragging_moved, set_is_dragging_moved] = useState(false);

    const on_mouse_down_s = (e) => {
        if (!is_touch_device()) {
            set_is_dragging(true);
            set_start_pos(e.pageX - payment_list_scrollspace.current.offsetLeft);
            set_scroll_pos(payment_list_scrollspace.current.scrollLeft);
            set_is_dragging_moved(false);
        }
    };

    const on_mouse_leave_s = () => {
        if (!is_touch_device()) {
            set_is_dragging(false);
        }
    };

    const on_mouse_up_s = (e) => {
        if (!is_touch_device()) {
            if (is_dragging_moved) {
                e.preventDefault();
                e.stopPropagation();
            }
            set_is_dragging(false);
        }
    };

    const on_mouse_move_s = (e) => {
        if (!is_touch_device()) {
            if (!is_dragging) return;
            const x = e.pageX - payment_list_scrollspace.current.offsetLeft;
            const walk = (x - start_pos) * 2;
            payment_list_scrollspace.current.scrollLeft = scroll_pos - walk;
            if (walk != 0) set_is_dragging_moved(true);
        }
    };

    
    // ---- Timers ----
    let [next_price_increase, set_next_price_increase] = useState([0,0]);
    let [increase_time, set_increase_time] = useState(0);
    let interval_ref = useRef(null);

    // ---- Onload ----
    useEffect(() => {

        (async function() {
            let next_price_increase = await get_next_price_increase();
            set_next_price_increase(next_price_increase);
        })();

    },[]);

    useEffect(() => {

        if (interval_ref.current != null) {
            clearInterval(interval_ref.current);
            interval_ref.current = null;
        }

        let time_to = Number(next_price_increase[1] - next_price_increase[0]);
        set_increase_time(time_to);

        interval_ref.current = setInterval(() => {
            set_increase_time(prev => {
                let new_time = prev; 
                if (new_time > 0) {
                    new_time--;
                }
                else {
                    new_time = 0;
                }
                return new_time;
            });
        }, 1000);

        return () => {
            if (interval_ref.current) {
                clearInterval(interval_ref.current);
            }
        };

    },[next_price_increase]);

    // ---- Discounts ----
    let { open_discount_charts } = useDiscountCharts();

    function set_discount(currency_index, amount) {
        set_currency_index(currency_index);
        setTimeout(function() {
            input_amount_ref.current.value = amount;
            input_value_changed(amount, currency_index);
        },500);
    }

    // ---- ToS ----
    let [agreed_tos, set_agreed_tos] = useState(true);

    // ---- Unlimit ----
    // let unlimit_instance = useRef(null);

    // function render_unlimit(event) {

    //     event.target.style = "opacity: 0.25; pointer-events: none;";

    //     let hint_amount = input_amount_ref.current.value;
    //     let currency = currencies[currency_index];

    //     if (hint_amount < currency.min || hint_amount > currency.max) {
    //         let params = {
    //             title: "Oh No!",
    //             content: hint_amount < currency.min ?
    //                 `The minimum purchase value is US$ 10 (${currency.min} ${currency.symbol})` :
    //                 `The maximum purchase value is US$ 10,000 (${currency.max} ${currency.symbol})`,
    //             buttons: [
    //                 {
    //                     title: hint_amount < currency.min ?
    //                         "Set To Min" :
    //                         "Set To Max",
    //                     callback: function() {
    //                         if (hint_amount < currency.min) {
    //                             input_amount_ref.current.value = currency.min;
    //                         }
    //                         else {
    //                             input_amount_ref.current.value = currency.max;
    //                         }
    //                     }
    //                 },
    //                 {
    //                     title: "Dismiss"
    //                 }
    //             ]
    //         }
    //         throw_error(params);
    //         event.target.style = "opacity: 1; pointer-events: auto;";
    //         return;
    //     }

    //     unlimit_instance.current = new GateFiSDK({
    //         merchantId: config.unlimit_partner_id,
    //         displayMode: "overlay",
    //         nodeSelector: "#overlay-button",
    //         isSandbox: true,
    //         externalId: "uidd333",
    //         walletAddress: "777898.eth",
    //         defaultFiat: {
    //             currency: "USD",
    //             amount: hint_amount
    //         },
    //         defaultCrypto: {
    //             currency: "USDT-BEP20"
    //         },
    //         styles: {
    //             type: "dark",
    //             primaryBackground: "rgba(80, 80, 80, 0.25)",
    //             secondaryBackground: "#061420",
    //             primaryColor: "#ffff00",
    //             secondaryColor: "#00c8ff",
    //             primaryTextColor: "black"
    //         },
    //         walletLock: true,
    //         fiatAmountLock: true,
    //         fiatCurrencyLock: true,
    //         cryptoCurrencyLock: true,
    //         hideAsset: true,
    //         hideThemeSwitcher: true
    //     });

    //     unlimit_instance.current.subscribe(GateFiEventTypes.onClose, (type, payload) => {
    //         unlimit_instance.current.destroy()
    //     });

    //     let check_interval = setInterval(function() {
    //         let gate_fi_div = document.querySelector("#gatefi-sdk-overlay");
    //         if (!gate_fi_div) {
    //             clearInterval(check_interval);
    //         }
    //         else if (gate_fi_div.style.display == "none") {
    //             unlimit_instance.current.destroy();
    //             clearInterval(check_interval);
    //         }
    //     },100);

    //     event.target.style = "opacity: 1; pointer-events: auto;";

    // }

    // ---- Increment Intervals ----
    let increment_interval_ref = useRef(null);
    let speed_step = 50;
    let initial_speed = 500;
    let max_speed = 50;
    let step_size = 0;
    let max_step_size = 1;

    function start_interval(callback) {

        callback();

        let value = input_amount_ref.current.value;
        let slice_index = -1;
        let loop_count = value.slice(slice_index);
        let cur_speed = initial_speed;
        let cur_step_size = step_size;

        if (increment_interval_ref.current) {
            clearInterval(increment_interval_ref.current);
        }

        function interval_callback() {

            if (cur_step_size < max_step_size && loop_count != 0 && loop_count % 10 == 0) {
                cur_step_size += 1;
                loop_count += 1;
            }

            callback(cur_step_size);

            if (cur_speed > max_speed) {
                cur_speed -= speed_step;
                clearInterval(increment_interval_ref.current);
                increment_interval_ref.current = setInterval(interval_callback, cur_speed);
            }
            loop_count++;
        }

        increment_interval_ref.current = setInterval(interval_callback, cur_speed);
    }

    function stop_interval() {
        if (increment_interval_ref.current) {
            clearInterval(increment_interval_ref.current);
            increment_interval_ref.current = null;
        }
    }

    const is_touch_device = () => {
        return ('ontouchstart' in window || navigator.maxTouchPoints > 0 || navigator.msMaxTouchPoints > 0);
    };

    return (

        <div className="notranslate">
            <div className="countdown_container">
                <div className="central_text_indicator translate">Price Increase In</div>
                <div className="countdown_inner_container">
                    <div className="countdown_item">00</div>
                    <div className="countdown_item">{format_time_mm_ss(increase_time).split(":")[0]}</div>
                    <div className="countdown_item">{format_time_mm_ss(increase_time).split(":")[1]}</div>
                    <div className="countdown_item">{format_time_mm_ss(increase_time).split(":")[2]}</div>
                </div>
                <div className="translate">
                    <div className="countdown_item_indicator">DAYS</div>
                    <div className="countdown_item_indicator">HOURS</div>
                    <div className="countdown_item_indicator">MINS</div>
                    <div className="countdown_item_indicator">SECS</div>
                </div>

                
            </div>

            <div className="payment_method_container notranslate" ref={payment_list_scrollspace}
                onMouseDown={on_mouse_down_s}
                onMouseLeave={on_mouse_leave_s}
                onMouseUp={on_mouse_up_s}
                onMouseMove={on_mouse_move_s}
                style={{cursor: is_dragging ? 'grabbing' : 'grab'}}
                >
                    {/* width: "806px" 701px*/}
                <div className="payment_method_inner" style={{width: "701px"}}>
                    {
                        currencies.map((currency,index)=>(<>
                            {
                                index != 5 &&
                                <>
                                <div key={index} className={`payment_method_item non_selectable gray_button_hover ${currency_index==index?"selected":""}`} onClick={()=>set_currency_index(index)}>
                                    <img className="payment_method_icon" src={currency.icon}/>
                                    <div className="payment_method_label">{currency.short_display}</div>
                                </div>
                                </>
                            }</>
                        ))
                    }
                    
                </div>
            </div>
            <div className="small_spacer"></div>
            <p className="small_text">You Pay:</p>
            <div className="small_spacer"></div>
            <div className="payment_input_container notranslate" ref={input_amount_outter_ref}>
                <div className="payment_input_currency_selector gray_button_hover" onClick={()=>open_currency_selector({set: set_currency_index})}>
                    <img className="payment_input_selector_icon" src={currencies[currency_index].icon}/>
                    <img className="payment_input_selector_extra_icon" src={down_icon}/>
                </div>
                <input className="value_input" onChange={debounce_input_value_changed} ref={input_amount_ref} type="number" inputMode="decimal" onFocus={()=>on_focus_input_ref(input_amount_outter_ref)} onBlur={()=>on_blur_input_ref(input_amount_outter_ref)}/>
                <div className="currency_label">{currencies[currency_index].symbol}</div>

                <div className="edit_input_button gray_button_hover" onClick={()=>input_amount_ref.current.focus()}>
                    <img className="edit_input_button_icon" src={edit_icon}/>
                </div>

                <div className="stepper_up_input_button gray_button_hover"
                onMouseDown={ ()=> { if (!is_touch_device()) { start_interval((offset) => { increment_ref(input_amount_ref, offset); debounce_input_value_changed(); }) } } }
                onMouseUp={ ()=> { if (!is_touch_device()) { stop_interval(); } } }
                onMouseLeave={ ()=> { if (!is_touch_device()) { stop_interval(); } } }
                onTouchStart={ ()=> { if (is_touch_device()) { start_interval((offset) => { increment_ref(input_amount_ref, offset); debounce_input_value_changed(); }) } } }
                onTouchEnd={ ()=> { if (is_touch_device()) { stop_interval(); } } }
                >+</div>
                <div className="stepper_down_input_button gray_button_hover"
                onMouseDown={ ()=> { if (!is_touch_device()) { start_interval((offset) => { decrement_ref(input_amount_ref, offset); debounce_input_value_changed(); }) } } }
                onMouseUp={ ()=> { if (!is_touch_device()) { stop_interval(); } } }
                onMouseLeave={ ()=> { if (!is_touch_device()) { stop_interval(); } } }
                onTouchStart={ ()=> { if (is_touch_device()) { start_interval((offset) => { decrement_ref(input_amount_ref, offset); debounce_input_value_changed(); }) } } }
                onTouchEnd={ ()=> { if (is_touch_device()) { stop_interval(); } } }
                >-</div>
                {/* <div className="up_input_button gray_button_hover" onClick={()=>{ increment_ref(input_amount_ref); input_value_changed(); }}>+</div>
                <div className="down_input_button gray_button_hover" onClick={()=>{ decrement_ref(input_amount_ref); input_value_changed(); }}>-</div> */}
            </div>
            <div className="small_spacer"></div>
            <p className="small_text">You Receive:</p>
            <div className="small_spacer"></div>
            <div className="payment_input_container notranslate">
                <div className="payment_input_currency_selector">
                    <img className="payment_input_selector_icon" src={logo_dark_icon}/>
                    <img className="payment_input_selector_extra_icon" src={equal_icon}/>
                </div>
                <input className="value_input_max" ref={output_amount_ref}/>
                <div className="currency_label_max">JBOLT</div>
            </div>
            <div className="medium_spacer"></div>
            <div className="terms_container">
                <div className={`terms_button blue_button_hover ${agreed_tos?"selected":""}`} onClick={()=>set_agreed_tos(!agreed_tos)}>
                    { agreed_tos && 
                        <img src={tick_icon} className="terms_icon_ticked"/>
                    }
                </div>
                <div className="terms_text translate">I agree to the <u className="underlined_text" onClick={()=>window.open(external_links.terms)}>Terms of Use</u> and <u className="underlined_text" onClick={()=>window.open(external_links.privacy)}>Privacy Policy</u>, certify that I have fully understood the <u className="underlined_text" onClick={()=>window.open(external_links.disclaimer)}>Risks Disclaimer</u>, and confirm that I am not from a <u className="underlined_text" onClick={()=>window.open(external_links.blocked_countries)}>Blocked Country</u>.</div>
            </div>
            <div className="medium_spacer"></div>
            {/* <div className="payment_button yellow_glow title_font_family yellow_button_hover" style={{display: agreed_tos && currency_index == 0 ? "block": "none"}} id={"overlay-button"} onClick={render_unlimit}>BUY NOW</div>
            {
                (!agreed_tos || currency_index != 0) &&
                <div className="payment_button yellow_glow title_font_family yellow_button_hover" onClick={purchase}>BUY NOW</div>
            } */}

            <div className={`payment_button yellow_glow title_font_family yellow_button_hover large_text ${hard_stop===null?"temp_unselectable":""}`} onClick={purchase}>BUY NOW</div>

            <div className="medium_spacer"></div>

            {
                input_amount_ref.current && input_amount_ref.current.value &&
                parseFloat(input_amount_ref.current.value) > 0 &&
                <>
                {
                next_discount != 1 &&
                    next_discount ?
                    <>
                        {
                            next_discount.current &&
                            <>
                                <p className="small_text central_text_indicator translate" style={{color: "var(--green_text)"}}>You get {next_discount.current.discount} extra free tokens!</p>
                                <div className="small_spacer"></div>
                            </>
                        }
                        
                        <div className="small_text central_text_indicator"><a className="translate">Buy over</a> ${next_discount.usd_threshold}{currency_index!=0&&` (${next_discount.value} ${currencies[currency_index].symbol})`} <a className="translate">and get</a> {next_discount.discount} extra tokens</div>
                        <div className="medium_spacer"></div>
                        <div className="outer_progress_bar">
                            <div className="inner_progress_bar" style={{width: `${next_discount.distance}%`}}></div>
                        </div>
                        <div className="small_spacer"></div>
                        <p className="small_text"></p>
                        {/* <div className="small_spacer"></div>
                        <p className="small_text central_text_indicator"><a className="translate">Current:</a> {next_discount.current ? next_discount.current.discount : "0%"} | <a className="translate">Next:</a> {next_discount.discount}</p> */}
                        <div className="small_spacer"></div>
                        <div className="small_text central_text_indicator underlined_text translate" onClick={()=>open_discount_charts({exchange_rates, currency_index, set:set_discount})}><u>Full Discount Chart</u></div>
                    </> :
                    <>  
                        <div className="small_text central_text_indicator translate" style={{color: "var(--green_text)"}}>You Get 25% extra free tokens!</div>
                        <div className="small_spacer"></div>
                        <p className="small_text central_text_indicator translate">Maximum Discount!</p>
                        <div className="medium_spacer"></div>
                        <div className="small_text central_text_indicator underlined_text translate" onClick={()=>open_discount_charts({exchange_rates, currency_index, set:set_discount})}><u>Full Discount Chart</u></div>
                    </>
                }
                </>
            }

            
        </div>
    )

}

export default Sales_Widget_Inner;