import React, { createContext, useContext, useState, useEffect, useRef } from "react";

const ModalContext = createContext();

export function useLoadingOverlay() {
    return useContext(ModalContext);
}

let text_indicators = [
    "Loading...",
    // "Checking purchase history",
    "Validating request",
    "Creating purchase address",
    "Almost there",
    "Few more seconds",
    "So close...",
    "Just a second",
    "Sorry just 1 sec",
    "Still loading",
    "Don't leave",
    "Here we go",
    "Should appear now"
];

let text_interval;
let current_text_index = 0;

export function Loading_Overlay({ children }) {

    let [show_modal, set_show_modal] = useState(false);
    let [animate_out, set_animate_out] = useState(false);
    let [current_text, set_current_text] = useState(text_indicators[0]);

    let open_loading_overlay = (modal_parameters) => {
        document.body.style.overflow = 'hidden';
        set_show_modal(true);
        try { window.$crisp.push(['do', 'chat:hide']); } catch (error) {}
        try { document.querySelector(".tg_support").style.display = "none"; } catch (error) {}
        current_text_index = 0;
        set_current_text(text_indicators[0]);
        text_interval = setInterval(function() {
            current_text_index=current_text_index+1<text_indicators.length?current_text_index+1:0;
            set_current_text(text_indicators[current_text_index]);
        },2000);
    }

    let close_loading_overlay = () => {
        set_animate_out(true);
        setTimeout(() => {
            document.body.style.overflow = 'visible';
            set_show_modal(false);
            set_animate_out(false);
        }, 250);
        try { window.$crisp.push(['do', 'chat:show']);; } catch (error) {}
        try { document.querySelector(".tg_support").style.display = "block"; } catch (error) {}
        current_text_index = 0;
        set_current_text(text_indicators[0]);
        clearInterval(text_interval);
    }

    let context_value = {
        open_loading_overlay,
        close_loading_overlay
    }

    return (
        <ModalContext.Provider value={context_value}>
            { children }
            { show_modal &&
                <div className="background_shader">
                <div className="outer_modal_click"></div>
                    <div className={`language_selector_container yellow_glow ${animate_out ? 'hide' : ''}`}>
                        <div className="purchase_funnel_container">
                            <div className="stick_text">
                                <div className="medium_spacer"></div>
                                <div className="title_container">
                                    <h2 className="central_text_indicator title_font_family">Buy JetBolt</h2>
                                </div>

                                <div className="medium_spacer"></div>

                                <div className="loading_container">
                                    <div className="loading_wheel"></div>
                                    <p className="loading_text">{current_text}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </ModalContext.Provider>);
}